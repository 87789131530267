import {Link} from '@remix-run/react';
import {css} from '@styled-system/css';
import {Box, HStack, Stack} from '@styled-system/jsx';
import {h3} from '@styled-system/recipes';
import {ReactNode} from 'react';

export type ContentCardProps = {
  link?: string
  imageSrc: string
  imageTags?: ReactNode[]
  title: string
  subTitle?: ReactNode
  children?: ReactNode
}

const ContentCard = (props: ContentCardProps) => {
  const {link, imageSrc, imageTags, title, subTitle, children} = props;

  const content = <>
    <Box h={275}>
      {imageTags && (
        <Stack
          direction='row'
          position="absolute"
          zIndex={1}
          width="full"
          p={2}
          justify="flex-end"
        >
          {imageTags}
        </Stack>
      )}
      <img
        src={imageSrc}
        className={css({
          objectFit: 'cover',
          height: 'full',
          width: 'full',
          borderRadius: 'lg'
        })}
        alt={title}
      />
    </Box>
    <HStack justify="space-between">
      <h3 className={h3()}>
        {title}
      </h3>
      {subTitle && <Box>
        {subTitle}
      </Box>}
    </HStack>
    {children && (
      <Box borderTop="base" pt={1} mt={1}>
        {children}
      </Box>
    )}
  </>

  return link ? <Link
    className={css({
      position: 'relative',
      '& img': {
        filter: 'grayscale(100%)',
        transition: 'filter 0.2s',
      },
      '& h3': {
        transition: 'color 0.2s',
      },
      _hover: {
        '& img': {
          filter: 'grayscale(0%)',
        },
        '& h3': {
          color: 'primary',
        }
      }
    })}
    to={link}
  >
    {content}
  </Link> : <Box>
    {content}
  </Box>;
}

export default ContentCard;
